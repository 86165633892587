import React from 'react';
import '../styles/pages/Portfolio.css';

function Portfolio() {
  return (
    <div className="portfolio">
      <h2>Mina Koncept och Exempel</h2>
      <p className="portfolio-intro">
        Upptäck ett urval av mina projekt som visar min expertis inom design och utveckling. Låt oss skapa något unikt tillsammans!
      </p>
      <div className="portfolio-gallery">
        <div className="portfolio-item">
          <img 
             src="/images/e-commerce-concept.png.webp"
            alt="E-commerce Website Concept" 
          />
          <h3>E-handelswebbplats</h3>
          <p>
            En ren och modern design för en onlinebutik som kombinerar användarvänlighet och visuellt tilltalande presentation.
          </p>
        </div>
        <div className="portfolio-item">
          <img 
            src="/images/restaurant-concept.png" 
            alt="Restaurant Website Concept" 
          />
          <h3>Restaurangwebbplats</h3>
          <p>
            En stilfull design som fokuserar på bokningar och en tydlig meny för restauranggäster.
          </p>
        </div>
        <div className="portfolio-item">
          <img 
            src="/images/business-concept.png" 
            alt="Business Website Concept" 
          />
          <h3>Företagswebbplats</h3>
          <p>
            Professionell och minimalistisk design för en konsultfirma som lyfter fram tjänster och kontaktmöjligheter.
          </p>
        </div>
        <div className="portfolio-item">
          <img 
            src="/images/travel-agency-concept.png" 
            alt="Travel Agency Website Concept" 
          />
          <h3>Resebyråwebbplats</h3>
          <p>
            En färgstark design för en resebyrå som inspirerar till nya äventyr och resor.
          </p>
        </div>
      </div>
      <div className="portfolio-footer">
        <p>
          Har du en idé eller vill se fler exempel? <strong>Kontakta mig</strong> för att diskutera ditt projekt och skapa något fantastiskt tillsammans!
        </p>
      </div>
    </div>
  );
}

export default Portfolio;
