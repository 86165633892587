import React from 'react';
import '../styles/pages/Användarvillkor.css'; // Assurez-vous de créer ce fichier CSS

function Användarvillkor() {
  return (
    <div className="terms-of-service">
      <h1>Användarvillkor</h1>
      <p>
        Välkommen till vår webbplats. Genom att använda vår tjänst accepterar du följande villkor. Läs dessa noggrant innan du fortsätter använda vår webbplats.
      </p>

      <h2>1. Allmänna villkor</h2>
      <p>
        Genom att använda denna webbplats godkänner du att följa och vara bunden av dessa användarvillkor samt eventuella tillämpliga lagar och förordningar. Om du inte samtycker till dessa villkor bör du inte använda vår webbplats.
      </p>

      <h2>2. Immateriella rättigheter</h2>
      <p>
        Allt innehåll på denna webbplats, inklusive texter, bilder, grafik och logotyper, tillhör oss eller våra licensgivare och skyddas av upphovsrätt och andra immateriella rättigheter. Du får inte använda eller reproducera detta material utan vårt skriftliga tillstånd.
      </p>

      <h2>3. Användning av webbplatsen</h2>
      <p>
        Du samtycker till att använda vår webbplats endast för lagliga ändamål och på ett sätt som inte kränker rättigheter eller begränsar andras användning av webbplatsen.
      </p>
      <ul>
        <li>Du får inte ladda upp virus, skadlig kod eller annat material som kan skada webbplatsen.</li>
        <li>Du får inte använda webbplatsen för att sprida olämpligt eller stötande innehåll.</li>
      </ul>

      <h2>4. Ansvarsbegränsning</h2>
      <p>
        Vi ansvarar inte för några direkta eller indirekta skador som uppstår genom användning av vår webbplats. Vi garanterar inte att webbplatsen alltid kommer att vara tillgänglig eller fri från fel.
      </p>

      <h2>5. Ändringar av villkor</h2>
      <p>
        Vi förbehåller oss rätten att ändra dessa användarvillkor när som helst. Ändringar kommer att publiceras på denna sida och träder i kraft omedelbart efter publicering.
      </p>

      <h2>6. Länkar till tredje parts webbplatser</h2>
      <p>
        Vår webbplats kan innehålla länkar till tredje parts webbplatser. Vi är inte ansvariga för innehållet eller integritetspolicyn på dessa webbplatser.
      </p>

      <h2>7. Kontakt</h2>
      <p>
        Om du har frågor om dessa användarvillkor, vänligen kontakta oss på:
      </p>
      <p>
        <strong>E-post:</strong> codec.sverige@gmail.com<br />
        <strong>Adress:</strong> karlstad, Sverige
      </p>
    </div>
  );
}

export default Användarvillkor;
