import React, { useState, useEffect } from 'react';
import '../../styles/common/CookieBanner.css'; // Assurez-vous de créer un fichier CSS pour cela

function CookieBanner() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="cookie-banner">
        <p>
          Vi använder cookies för att förbättra din upplevelse på vår webbplats. Genom att fortsätta använda webbplatsen accepterar du vår <a href="/cookies-policy">policy för cookies</a>.
        </p>
        <button onClick={acceptCookies}>Acceptera</button>
      </div>
    )
  );
}

export default CookieBanner;
