import React from 'react';
import '../styles/pages/Integritetspolicy.css'; // Assurez-vous d'avoir un fichier CSS correspondant

function Integritetspolicy() {
  return (
    <div className="privacy-policy">
      <h1>Integritetspolicy</h1>
      <p>
        Din integritet är viktig för oss. Denna integritetspolicy förklarar hur vi samlar in, använder och skyddar din information.
      </p>

      <h2>1. Vilken information samlar vi in?</h2>
      <p>
        Vi kan samla in följande typer av information:
      </p>
      <ul>
        <li>Personuppgifter som namn, e-postadress och telefonnummer när du fyller i ett kontaktformulär.</li>
        <li>Teknisk information såsom IP-adress, webbläsartyp och besökstid via cookies.</li>
      </ul>

      <h2>2. Hur använder vi din information?</h2>
      <p>
        Vi använder informationen för att:
      </p>
      <ul>
        <li>Ge svar på dina frågor och erbjuda kundsupport.</li>
        <li>Förbättra användarupplevelsen på vår webbplats.</li>
        <li>Analysera trafik och användarbeteende på webbplatsen.</li>
      </ul>

      <h2>3. Delning av information</h2>
      <p>
        Vi delar aldrig din information med tredje part utan ditt samtycke, förutom när det krävs enligt lag.
      </p>

      <h2>4. Cookies</h2>
      <p>
        Vår webbplats använder cookies för att förbättra din upplevelse. Du kan hantera dina cookie-inställningar i din webbläsare.
      </p>

      <h2>5. Dina rättigheter</h2>
      <p>
        Enligt GDPR har du rätt att:
      </p>
      <ul>
        <li>Begära tillgång till de personuppgifter vi har om dig.</li>
        <li>Begära att vi raderar eller korrigerar dina personuppgifter.</li>
        <li>Invända mot användningen av dina personuppgifter för specifika ändamål.</li>
      </ul>

      <h2>6. Säkerhet</h2>
      <p>
        Vi tar säkerheten för din information på allvar och vidtar lämpliga tekniska och organisatoriska åtgärder för att skydda dina uppgifter.
      </p>

      <h2>7. Ändringar i denna policy</h2>
      <p>
        Vi kan uppdatera denna integritetspolicy vid behov. Eventuella ändringar publiceras på denna sida.
      </p>

      <h2>8. Kontakt</h2>
      <p>
        Om du har frågor om denna integritetspolicy eller om du vill utöva dina rättigheter, vänligen kontakta oss på:
      </p>
      <p>
        <strong>E-post:</strong> codec.sverige@gmail.com<br />
        <strong>Adress:</strong> kristinehamn, Sverige
      </p>
    </div>
  );
}

export default Integritetspolicy;
