import React from 'react';
import '../styles/pages/CookiesPolicy.css'; // Assurez-vous de créer un fichier CSS pour cela

function CookiesPolicy() {
  return (
    <div className="cookies-policy">
      <h1>Policy för cookies</h1>
      <p>
        Den här sidan förklarar hur vi använder cookies och vilka val du har angående dem. Genom att använda vår webbplats accepterar du användningen av cookies i enlighet med denna policy.
      </p>

      <h2>Vad är cookies?</h2>
      <p>
        Cookies är små textfiler som lagras på din enhet när du besöker en webbplats. De används för att förbättra användarupplevelsen och samla in information om webbplatsanvändning.
      </p>

      <h2>Vilka typer av cookies använder vi?</h2>
      <ul>
        <li>
          <strong>Nödvändiga cookies:</strong> Dessa är väsentliga för att webbplatsen ska fungera korrekt och kan inte stängas av.
        </li>
        <li>
          <strong>Analytiska cookies:</strong> Dessa hjälper oss att förstå hur webbplatsen används och förbättra dess prestanda.
        </li>
        <li>
          <strong>Funktionella cookies:</strong> Dessa gör att vi kan erbjuda en bättre användarupplevelse genom att spara dina preferenser.
        </li>
      </ul>

      <h2>Hur kan jag hantera cookies?</h2>
      <p>
        Du kan hantera dina cookie-inställningar via din webbläsare. Observera att vissa funktioner på webbplatsen kan påverkas om du inaktiverar cookies.
      </p>

      <h2>Kontakt</h2>
      <p>
        Om du har frågor om vår policy för cookies, vänligen kontakta oss:
      </p>
      <p>
        <strong>E-post:</strong> codec.sverige@gmail.com<br />
        <strong>Adress:</strong> karlstad, Sverige
      </p>
    </div>
  );
}

export default CookiesPolicy;
