import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../styles/pages/Contact.css'; // Se till att CSS-filen finns

function Contact() {
  const [formData, setFormData] = useState({
    namn: '',
    epost: '',
    telefon: '',
    meddelande: '',
  });
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send(
      'service_m8mzozz', // Din Service ID
      'template_a3mlzrj', // Din Template ID
      {
        namn: formData.namn,
        epost: formData.epost,
        telefon: formData.telefon,
        meddelande: formData.meddelande,
      },
      '6vp1llYDkk0GAlzjR' // Din Public Key
    ).then(
      (response) => {
        console.log('SUCCESS!', response.status, response.text);
        setMessage('Meddelandet skickades framgångsrikt!');
        setFormData({ namn: '', epost: '', telefon: '', meddelande: '' });
      },
      (error) => {
        console.error('FAILED...', error);
        setMessage('Ett fel uppstod. Försök igen.');
      }
    );
  };

  return (
    <div className="form-section">
      <h2>Kontakta oss</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="namn">Namn:</label>
        <input
          type="text"
          id="namn"
          name="namn"
          placeholder="Ange ditt namn"
          value={formData.namn}
          onChange={handleChange}
          required
        />

        <label htmlFor="epost">E-postadress:</label>
        <input
          type="email"
          id="epost"
          name="epost"
          placeholder="Ange din e-postadress"
          value={formData.epost}
          onChange={handleChange}
          required
        />

        <label htmlFor="telefon">Telefonnummer:</label>
        <input
          type="tel"
          id="telefon"
          name="telefon"
          placeholder="Ange ditt telefonnummer"
          value={formData.telefon}
          onChange={handleChange}
          required
        />

        <label htmlFor="meddelande">Meddelande:</label>
        <textarea
          id="meddelande"
          name="meddelande"
          placeholder="Skriv ditt meddelande"
          value={formData.meddelande}
          onChange={handleChange}
          required
        />

        <button type="submit">Skicka</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}
export default Contact;
