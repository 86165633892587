import React, { useEffect } from 'react';
import '../styles/pages/Testimonials.css';

function Testimonials() {
  useEffect(() => {
    const testimonials = document.querySelectorAll('.testimonial');
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.2 } // Réagit lorsque 20% de l'élément est visible
    );

    testimonials.forEach((testimonial) => observer.observe(testimonial));

    return () => {
      testimonials.forEach((testimonial) => observer.unobserve(testimonial));
    };
  }, []);

  return (
    <section className="testimonials">
      <h2>Vad våra kunder säger</h2>
      <div className="testimonial-container">
        <div className="testimonial">
          <i className="quote-icon">❝</i>
          <p>"Hemsidan som de skapade för vårt företag är fantastisk! Väldigt professionell och enkel att använda."</p>
          <h3>- Anna Karlsson, Ägare av Modern Butik</h3>
        </div>
        <div className="testimonial">
          <i className="quote-icon">❝</i>
          <p>"Vi ökade vår kundbas med 40% tack vare den nya hemsidan. Rekommenderas starkt!"</p>
          <h3>- Johan Svensson, Restaurangchef</h3>
        </div>
        <div className="testimonial">
          <i className="quote-icon">❝</i>
          <p>"Snabbt, enkelt och mycket kreativt. Jag kommer definitivt att arbeta med dem igen!"</p>
          <h3>- Maria Lindberg, Frilansdesigner</h3>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
