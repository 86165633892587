import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/common/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Section Om Oss */}
        <div className="footer-section">
          <h3>Om Oss</h3>
          <p>
            ProSite är din partner för professionell webbdesign och IT-lösningar. 
            Vi är engagerade i att skapa innovativa, skalbara och prisvärda tjänster 
            som driver din verksamhet framåt.
          </p>
        </div>
        {/* Section Snabblänkar */}
        <div className="footer-section">
          <h3>Snabblänkar</h3>
          <ul>
            <li><Link to="/">Hem</Link></li>
            <li><Link to="/services">Tjänster</Link></li>
            <li><Link to="/portfolio">Projekt</Link></li>
            <li><Link to="/contact">Kontakt</Link></li>
          </ul>
        </div>
        {/* Section Kontakta Oss */}
        <div className="footer-section">
          <h3>Kontakta Oss</h3>
          <p><strong>E-post:</strong> codec.sverige@gmail.com</p>
          <p><strong>Adress:</strong> Kristinehamn, Sverige</p>
        </div>
      </div>
      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p>© {new Date().getFullYear()} ProSite - Alla rättigheter förbehållna.</p>
        <nav>
          <Link to="/integritetspolicy">Integritetspolicy</Link> |{' '}
          <Link to="/användarvillkor">Användarvillkor</Link>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
