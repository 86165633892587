import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './common/Header';
import Footer from './common/Footer';
import BackToTopButton from './common/BackToTopButton';
import CookieBanner from './common/CookieBanner';

// Pages
import Home from '../pages/Home';
import Services from '../pages/Services';
import ServiceDetails from '../pages/ServiceDetails';
import Pricing from '../pages/Pricing';
import Portfolio from '../pages/Portfolio';
import Testimonials from '../pages/Testimonials';
import Contact from '../pages/Contact';
import Integritetspolicy from '../pages/Integritetspolicy';
import Användarvillkor from '../pages/Användarvillkor';
import CookiesPolicy from '../pages/CookiesPolicy';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <CookieBanner />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services-overview" element={<ServiceDetails />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/integritetspolicy" element={<Integritetspolicy />} />
          <Route path="/användarvillkor" element={<Användarvillkor />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
        </Routes>
        <Footer />
        <BackToTopButton />
      </div>
    </Router>
  );
}

export default App;
