import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/pages/Home.css';

function Home() {
  return (
    <div className="home">
      {/* Section Hero */}
      <section className="hero">
        <div className="hero-text">
          <h1>Professionell Webbdesign för Alla Behov</h1>
          <p>
            Vi hjälper småföretag och entreprenörer att bygga moderna, responsiva och prisvärda hemsidor. Med våra lösningar når du nya kunder och tar ditt företag till nästa nivå.
          </p>
          <Link to="/contact" className="cta-button">Kontakta oss idag</Link>
        </div>
        <div className="hero-image-container">
          <img
            src="/images/travel-agency-concept.png"
            alt="Illustration av professionell webbdesign"
            className="hero-image"
          />
        </div>
      </section>

      {/* Section Why Choose Us */}
      <section className="why-us">
        <h2>Varför välja oss?</h2>
        <div className="why-us-list">
          <div className="why-us-item">
            <span className="icon">📱</span>
            <h3>Responsiv Design</h3>
            <p>
              Våra hemsidor fungerar sömlöst på alla enheter, från smartphones till datorer.
            </p>
          </div>
          <div className="why-us-item">
            <span className="icon">🔍</span>
            <h3>SEO-optimering</h3>
            <p>
              Vi hjälper dig att synas bättre online och attrahera rätt målgrupp.
            </p>
          </div>
          <div className="why-us-item">
            <span className="icon">🛒</span>
            <h3>E-handelslösningar</h3>
            <p>
              Skapa en effektiv onlinebutik som ökar din försäljning.
            </p>
          </div>
          <div className="why-us-item">
            <span className="icon">💡</span>
            <h3>Support & Underhåll</h3>
            <p>
              Vi erbjuder löpande support och underhåll för att hålla din webbplats i toppskick.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
