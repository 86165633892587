import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/common/Header.css';

function Header() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isScrollingUp, setScrollingUp] = useState(true);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset;

      if (currentScrollPosition < lastScrollPosition) {
        setScrollingUp(true); // Scroll up
      } else {
        setScrollingUp(false); // Scroll down
      }

      setLastScrollPosition(currentScrollPosition);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollPosition]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <header className={`header ${isScrollingUp ? '' : 'hidden'}`}>
      <div className="header-container">
        <h1 className="logo">ProSite</h1>
        <button
          className="menu-toggle"
          onClick={toggleMenu}
          aria-label="Toggle menu"
          aria-expanded={isMenuOpen}
          aria-controls="main-navigation"
        >
          ☰
        </button>
        <nav
          ref={menuRef}
          id="main-navigation"
          className={`navbar ${isMenuOpen ? 'show' : ''}`}
        >
          <ul>
            <li><Link to="/" onClick={closeMenu}>Hem</Link></li>
            <li><Link to="/services" onClick={closeMenu}>Tjänster</Link></li>
            <li><Link to="/pricing" onClick={closeMenu}>Priser</Link></li>
            <li><Link to="/portfolio" onClick={closeMenu}>Projekt</Link></li>
            <li><Link to="/testimonials" onClick={closeMenu}>Kundrecensioner</Link></li>
            <li><Link to="/contact" onClick={closeMenu}>Kontakta oss</Link></li>
          </ul>
        </nav>
      </div>
      {isMenuOpen && <div className="overlay" onClick={closeMenu}></div>}
    </header>
  );
}

export default Header;
