import React from 'react';
import '../styles/pages/ServiceDetails.css';

function ServiceDetails() {
  return (
    <section className="service-details">
      <h2>Våra Tjänster – Anpassade för Dina Behov</h2>
      <p>
        Är du en student med en fantastisk idé, en småföretagare med en stram budget, eller en entreprenör som söker en enkel och effektiv lösning? Vi har rätt tjänster för dig. Vi skapar moderna och funktionella webbplatser som inte bara är visuellt tilltalande, utan även ger dig resultat snabbt och till en rimlig kostnad.
      </p>
      <p>
        Vi använder <strong>React.js</strong> för att skapa användarvänliga gränssnitt som engagerar dina kunder och <strong>Node.js</strong> för att bygga robusta backend-lösningar. Detta gör att vi kan leverera skalbara och snabba webbplatser som passar alla typer av verksamheter.
      </p>
      <ul>
        <li><strong>Responsiva:</strong> Din webbplats kommer att se fantastisk ut på både mobil och dator.</li>
        <li><strong>Ekonomiska:</strong> Våra lösningar är kostnadseffektiva både vid utveckling och underhåll.</li>
        <li><strong>Datasäkra:</strong> Vi prioriterar säkerheten för dig och dina kunder.</li>
        <li><strong>Resultatinriktade:</strong> Vårt mål är att hjälpa dig att attrahera fler kunder och växa ditt företag.</li>
      </ul>
      <p>
        Med våra flexibla prispaket kan vi skapa allt från en enkel hemsida till en avancerad webbapplikation som matchar dina behov och din budget. Vi ser också till att din webbplats är lätt att underhålla, så att du kan fokusera på det som är viktigast – din verksamhet.
      </p>
      <a href="/contact" className="contact-us-link">Kontakta oss och starta ditt projekt idag →</a>
    </section>
  );
}

export default ServiceDetails;
