import React from 'react';
import '../styles/pages/Pricing.css';

function Pricing() {
  return (
    <section className="pricing">
      <div className="pricing-header">
        <h2>Våra Prispaket</h2>
        <p className="pricing-intro">
          Anpassade lösningar för dina behov. Välj det paket som passar dig och låt oss arbeta tillsammans för att skapa något fantastiskt!
        </p>
      </div>
      <div className="pricing-table">
        <div className="pricing-card">
          <h3>Startpaket</h3>
          <p className="price">5,000 SEK</p>
          <ul>
            <li>Responsiv design (1 sida)</li>
            <li>Grundläggande SEO</li>
            <li>Leveranstid: 1-2 veckor</li>
            <li>1 veckas support</li>
          </ul>
          <a href="#contact" className="contact-link">Kontakta mig</a>
        </div>
        <div className="pricing-card popular">
          <span className="badge">Bästa valet</span>
          <h3>Affärspaket</h3>
          <p className="price">10,000 SEK</p>
          <ul>
            <li>3-5 sidor med modern design</li>
            <li>SEO-optimering</li>
            <li>Kontaktformulär</li>
            <li>Leveranstid: 2-4 veckor</li>
            <li>2 veckors support</li>
          </ul>
          <a href="#contact" className="contact-link">Kontakta mig</a>
        </div>
        <div className="pricing-card">
          <h3>Premiumpaket</h3>
          <p className="price">20,000 SEK</p>
          <ul>
            <li>Skräddarsydd design</li>
            <li>Integration av blogg eller galleri</li>
            <li>Avancerad SEO</li>
            <li>Leveranstid: 4-6 veckor</li>
            <li>1 månads support</li>
          </ul>
          <a href="#contact" className="contact-link">Kontakta mig</a>
        </div>
        <div className="pricing-card">
          <h3>Underhåll & Uppdatering</h3>
          <p className="price">1,500 SEK/månad</p>
          <ul>
            <li>Regelbundna säkerhetsuppdateringar</li>
            <li>Små ändringar i innehåll</li>
            <li>Prioriterad teknisk support</li>
          </ul>
          <a href="#contact" className="contact-link">Kontakta mig</a>
        </div>
      </div>
      <div className="pricing-footer">
        <p>
          Har du specifika krav eller en annan budget? <strong>Kontakta mig</strong>, så kan vi hitta en lösning tillsammans!
        </p>
      </div>
    </section>
  );
}

export default Pricing;
