import React from 'react';
import '../styles/pages/Services.css';

function Services() {
  return (
    <section className="services">
      <div className="services-header">
        <h2>Våra Tjänster – Skapa Din Digitala Framgång</h2>
        <p className="services-intro">
          Vi erbjuder en komplett uppsättning digitala tjänster för att hjälpa ditt företag att växa och lyckas online. Från design till optimering – vi gör det enkelt och effektivt.
        </p>

      </div>
      <div className="service-cards">
        <div className="service-card">
          <img src="/images/website-design.png.webp" alt="Web Design Icon" className="service-icon" />
          <h3>Webbdesign</h3>
          <p>
            Vi skapar användarvänliga, moderna och mobilanpassade hemsidor som stärker ditt varumärke och engagerar dina kunder.
          </p>
        </div>
        <div className="service-card">
          <img src="/images/icon-ecommerce.png" alt="E-commerce Icon" className="service-icon" />
          <h3>E-handelslösningar</h3>
          <p>
            Bygg en robust onlinebutik med enastående design och intuitiva funktioner som gör shopping till en njutning för dina kunder.
          </p>
        </div>
        <div className="service-card">
          <img src="/images/icon-seo.png" alt="SEO Icon" className="service-icon" />
          <h3>SEO & Digital Marknadsföring</h3>
          <p>
            Bli synlig för rätt målgrupp med avancerad SEO och datadriven marknadsföring som ger mätbara resultat.
          </p>
        </div>
        <div className="service-card">
          <img src="/images/icon-branding.png" alt="Branding Icon" className="service-icon" />
          <h3>Branding & Identitet</h3>
          <p>
            Skapa ett minnesvärt varumärke med en unik identitet som kommunicerar ditt budskap och bygger förtroende.
          </p>
        </div>
      </div>
      <div className='learn-more-container'>
      <a href="/services-overview" className="learn-more-all">Läs mer om våra tjänster →</a>

        </div>
    </section>
  );
}

export default Services;
